import Vue from 'vue';
import Router from 'vue-router';
import Redirect from './components/Redirect';

Vue.use(Router);

export default new Router({
  mode: 'history',
  history: true,
  routes: [{
    path: '/:key/:grade?/:env?',
    component: Redirect
  }],
});
