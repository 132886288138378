<template>
  <div>
    <p>Carregando...</p>
  </div>
</template>

<script>
import axios from 'axios';
const INDECX_URL = 'https://www.indecx.com.br';                   // Production
const INDECX_URL_S = 'https://www.indecx.pe';                     // Staging
const INDECX_REDIRECT = 'https://indecx.com/v2/redirect';         // Production
const INDECX_REDIRECT_S = 'https://api-indecx.com/v2/redirect';   // Staging

export default {
  async created() {
    let key = this.$route.params.key;
    let grade = this.$route.params.grade;
    let env = this.$route.params.env;
    let lang = this.$route.query.lang;
    if (!env) {
      if (grade) {
        if (grade.toLowerCase().indexOf('s') !== -1) {
          env = grade;
          grade = null;
        }
      }
    } else {
      if (!isNaN(env)) {
        const aux = env;
        env = grade;
        grade = aux;
      }
    }
    if (env) {
      // env = 's';
      key = key + '/' + env;
    }
    const pageUrl = env ? INDECX_URL_S : INDECX_URL;
    const redirectUrl = env ? INDECX_REDIRECT_S : INDECX_REDIRECT;
    if (key) {
      try {
        const response = await axios.post(
          `${redirectUrl}`,
          { key },
        );
        let finalUrl = response.data;
        if (grade) {
          finalUrl += `/${grade}`;
        }
        if (lang) {
          finalUrl += `?lang=${lang}`;
        }
        window.location.href = finalUrl;
      } catch (err) {
        window.location.replace(pageUrl);
      }
    } else {
      window.location.replace(INDECX_URL);
    }
  }
}

</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
